<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">

      <!-- <h1 class="logo mr-auto"><router-link to="/">WeekFlash</router-link></h1>-->
      <!-- Uncomment below if you prefer to use an image logo -->
      <a href="index.html" class="logo mr-auto"><img src="/assets/img/LogoHome.png" alt="Logo WeekFlash" class="img-fluid"></a>
      
     
      

    </div>
  </header><!-- End Header -->
  
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright {{ year }} <strong><span>WeekFlash</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/enno-free-simple-bootstrap-template/ -->
        <router-link to="/termsofuse">Terms of use</router-link> • <router-link to="/privacypolicy">Privacy policies</router-link>
      </div>
    </div>
  </footer><!-- End Footer -->
</template>

<script>


export default {
    data: function() {
       return {
          year: new Date().getFullYear()
        }
    }
  
}
</script>

<style>

</style>
