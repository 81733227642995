import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyEn.vue')
  },
  {
    path: '/politicadeprivacidad',
    name: 'Politica de Privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyEs.vue')
  },
  {
    path: '/termsofuse',
    name: 'Terms of Use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsEn.vue')
  },
  {
    path: '/terminosdeuso',
    name: 'Terminos de Uso',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsEs.vue')
  },
  {
    path: '/@:userName',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')
  },
  {
    path: '/f/:favoriteId',
    name: 'Favorite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Favorite.vue')
  },
  {
    path: '/p/:planId',
    name: 'Plan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Plan.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
