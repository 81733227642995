<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Do things you love</h1>
          <h2>Discover and share plans with your friends.</h2>
          <div class="d-flex">
            <a href="https://apps.apple.com/app/weekflash/id1303585786" class="btn-get-started scrollto">Download App</a>
            <a href="https://www.youtube.com/watch?v=wIBY1J5toWY" class="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"> Watch Video <i class="icofont-play-alt-2"></i></a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="assets/img/wf-home2.png" class="img-fluid animated" style="max-width: 140%;" alt="Using WeekFlash App">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  }
}
</script>
